<template>
  <component :is="commonComponent" id="blog" classs="blog">
    <div class="blog-container pc_padding m_set_width">
      <h1 class="m-b-30 head-1">
        Greed in the Bible
      </h1>

      <div class="autor m-b-30">
        <div>Author: Irene </div>
        <div>Publish: 2025/1/3 </div>
      </div>

      <AdComponent
        v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
        ref="ads-blog-1" class="ads-insert-max m-t-40" :ads="adsensConfig?.blog"
        :showDebug="showDebug" />

      <p>
        The concept of coveting in the Bible is highly significant for understanding its morality
        and ethics. Essentially, coveting is having a strong longing for what belongs to others.
        This simple yet profound concept, deeply rooted in Biblical teachings like the Ten
        Commandments, reveals much about human nature and spiritual expectations.In this article, we
        will take a deeper look at greed as described in the Bible.
      </p>

      <p>
        In a world where material pursuits often overstep ethical limits, exploring coveting in the
        Bible gives valuable insights. It helps us understand the complex relationship between
        desire, morality, and spiritual well-being.
      </p>

      <h2>Defining &quot;Greed&quot; in Biblical Terms</h2>

      <p>
        The term &#39;covet&#39; in the Bible has Hebrew and Greek origins with different shades of
        meaning. It has both positive and negative aspects. Positively, it can be a desire for
        spiritual and virtuous things, though this is less emphasized. Negatively, it is a sin, as
        clearly stated in the Tenth Commandment which prohibits coveting a neighbor&#39;s various
        possessions and relationships. This shows the danger of material desires overpowering moral
        and spiritual duties.
      </p>

      <p>
        <img alt="img"
          src="@/assets/N2Y5NzQzNWRlMDhlMGY5OGFmMDJkOGJjNTUwN2I5YTdfYXJWY0JDNU9BNEI5eEV3emFRaHFWYVp0ZVFqYko0NVNfVG9rZW46RFRSUmJ4R1VKb2ZhcTR4eEl6TWMwV3B1blJlXzE3MzMzODA5NjA6MTczMzM4NDU2MF9WNA.png">
      </p>

      <h2>Jesus Christ and the Apostle Paul on Greed</h2>

      <p>
        In the New Testament, Jesus and Paul expand on this. Jesus warns against loving money and
        material things, as seen in the story of the rich young man. Paul equates covetousness with
        idolatry and the root of all evil. They both stress the importance of contentment and
        spiritual wealth over earthly riches.
      </p>

      <h2>Overcoming Greedy Perspectives</h2>

      <p>
        To overcome covetousness, a Biblical perspective offers ways. Firstly, surrendering to
        Christ and relying on God. Then, focusing on gratitude and contentment as in Philippians
        4:11 - 13. Also, seeking Godly virtues and eternal values like in Matthew 6:33. Fostering a
        spirit of generosity through giving and sharing is important too. Regular self-reflection
        and prayer, guided by Psalm 139:23 - 24, help realign our desires with God&#39;s will. By
        following these, we can handle covetousness and balance enjoying life&#39;s blessings with
        spiritual and ethical values.
      </p>

      <h2>Case Studies from the Bible</h2>

      <p>
        The Bible is replete with stories and parables that illustrate the dangers and consequences
        of covetousness. These narratives highlight how personal covetous desires can have
        far-reaching, negative consequences.
      </p>

      <p>*Achan’s Covetousness**</p>

      <p>
        One poignant example is the story of Achan in the Book of Joshua. Achan’s coveting and
        subsequent theft of forbidden items led not only to his downfall but also brought trouble
        upon the entire Israelite community.
      </p>

      <p>Joshua 7:19-26 (NIV):</p>

      <p>
        Then Joshua said to Achan, “My son, give glory to the Lord, the God of Israel, and honor
        him. Tell me what you have done; do not hide it from me.” Achan replied, “It is true! I have
        sinned against the Lord, the God of Israel. This is what I have done: When I saw in the
        plunder a beautiful robe from Babylonia, two hundred shekels of silver and a bar of gold
        weighing fifty shekels, I coveted them and took them. They are hidden in the ground inside
        my tent, with the silver underneath.” So Joshua sent messengers, and they ran to the tent,
        and there it was, hidden in his tent, with the silver underneath. They took the things from
        the tent, brought them to Joshua and all the Israelites and spread them out before the Lord.
        Then Joshua, together with all Israel, took Achan son of Zerah, the silver, the robe, the
        gold bar, his sons and daughters, his cattle, donkeys and sheep, his tent and all that he
        had, to the Valley of Achor. Joshua said, “Why have you brought this trouble on us? The Lord
        will bring trouble on you today.” Then all Israel stoned him, and after they had stoned the
        rest, they burned them. Over Achan they heaped up a large pile of rocks, which remains to
        this day. Then the Lord turned from his fierce anger. Therefore that place has been called
        the Valley of Achor ever since.
      </p>

      <p>
        <img alt="img"
          src="@/assets/NGE4ODZmOWZhYjliN2RiM2M3MTdiMTY0N2JiMTk4Y2VfWm9XdWpBbDB4djlia3JJMGJ3azN0MklOVUpVc3RjTGhfVG9rZW46RHJHRWIyTU5xb3dBYjB4cklRZ2N4MU83bmhlXzE3MzMzODA5NjA6MTczMzM4NDU2MF9WNA.png">
      </p>

      <p><strong>King David’s Covetousness</strong></p>

      <p>
        Another significant example is King David’s desire for Bathsheba, which led to adultery and
        murder. This story, found in Samuel, starkly demonstrates how covetousness can lead even a
        man after God’s own heart to commit grave sins. It serves as a cautionary tale of how
        unchecked desires can lead to a cascade of sinful actions and devastating outcomes.
      </p>

      <p>Samuel 11:2-5 (NIV)</p>

      <p>
        One evening David got up from his bed and walked around on the roof of the palace. From the
        roof he saw a woman bathing. The woman was very beautiful, and David sent someone to find
        out about her. The man said, “She is Bathsheba, the daughter of Eliam and the wife of Uriah
        the Hittite.” Then David sent messengers to get her. She came to him, and he slept with her.
        (Now she was purifying herself from her monthly uncleanness.) Then she went back home. The
        woman conceived and sent word to David, saying, “I am pregnant.”
      </p>

      <p>Samuel 11:14-17</p>

      <p>
        In the morning David wrote a letter to Joab and sent it with Uriah. In it he wrote, “Put
        Uriah out in front where the fighting is fiercest. Then withdraw from him so he will be
        struck down and die.” So while Joab had the city under siege, he put Uriah at a place where
        he knew the strongest defenders were. When the men of the city came out and fought against
        Joab, some of the men in David’s army fell; moreover, Uriah the Hittite died.
      </p>

      <p>
        These case studies are not merely historical accounts; they offer enduring lessons. They
        teach the importance of guarding one’s heart against covetous thoughts and the necessity of
        adhering to ethical and spiritual principles. Furthermore, they underscore the Bible’s
        emphasis on the internal dimension of sin – that sin begins in the heart and mind before
        manifesting in actions.
      </p>

      <h2>Conclusion</h2>

      <p>
        In exploring the meaning of greed in the Bible, we discover a profound truth: Greed is more
        than a desire for material wealth; it is a matter of the heart. From the Ten Commandments to
        the teachings of Jesus and Paul, the Bible&#39;s teaching on this subject offers timeless
        wisdom for identifying and overcoming greedy desires. By reflecting on these teachings and
        applying them to our lives, we can learn to balance our desires, prioritize spiritual and
        moral values, and cultivate contented and fulfilled lives in God&#39;s grace.
      </p>
    </div>

    <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
      ref="ads-blog-1" class="pc_padding m-t-40" :ads="adsensConfig?.blog" :showDebug="showDebug" />

    <div class="blog-list-container ">
      <div class="blog-list-title">
        Recommend Reading
      </div>
      <div class="blog-card-row">
        <div v-for="(data, i) in card" :key="i" class="blog-card-item cursor"
          @click="goto(data.routename)">
          <img :src="data.img" class="blog-card-image" :alt="data.title">
          <div class="blog-card-content">
            <div class="blog-card-title">
              {{ data.title }}
            </div>
            <div class="blog-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="blog-card-btn">
            Read More <img src="@/assets/readmore.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <a href="/blogs">
      <div class="blog-all-btn cursor">
        View All
      </div>
    </a>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/blog_base.scss';

export default {
  name: 'Blog2',
  components: {
    AdComponent,
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
      card: [
        {
          img: require('@/assets/blog-1.png'),
          title: 'Seven Powerful Ways of Thinking in the Bible',
          desc: "German writer Goethe said that all my profound thoughts come from my belief in the Bible; my moral life and literary works are all guided by the Bible. The Bible is the most reliable and powerful capital in my life, and it is really an inexhaustible treasure house. Understanding these 7 kinds of thinking in the Bible can help you embark on a path of understanding and have lively hope in life.",
          routename: 'seven-powerful-ways-of-thinking-in-the-bible',
        },
        {
          img: require('@/assets/blog-3.png'),
          title: 'What does the Bible say about judging others',
          desc: "In our daily lives, we often find ourselves in the position of passing judgment on others. Whether it's a quick assessment of someone's behavior, appearance, or choices, we seem to have an innate tendency to form opinions and judgments. But what does the Bible have to say about this all-too-common human trait?",
          routename: 'what-does-the-bible-say-about-judging-others',
        },
        {
          img: require('@/assets/blog-4.png'),
          title: 'Revelation from the Stories of People Who Overcame Suffering in the Bible',
          desc: "In the Bible, there are many people who have experienced suffering. They showed admirable tenacity and courage in difficult situations. With their faith and strength, they turned suffering into grace, making life and living more abundant and meaningful. Next, let us learn how they faced suffering, got out of difficulties, and made life better.",
          routename: 'revelation-from-the-stories-of-people-who-overcame-suffering-in-the-bible',
        },

      ],
    };
  },
  computed: {
    title () {
      return `Greed in the Bible | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `The concept of coveting in the Bible is highly significant for understanding its morality and ethics. Essentially, coveting is having a strong longing for what belongs to others. This simple yet profound concept, deeply rooted in Biblical teachings like the Ten Commandments, reveals much about human nature and spiritual expectations.In this article, we will take a deeper look at greed as described in the Bible.`
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (routername) {
      window.location.href = `/${routername}`
    },
    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos].indexOf(position) : this.adPosConfig?.[`${pos}PC`].indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互

  }
};
</script>
